<template>
  <div
    class="home"
    :class="{
      'd-flex align-center justify-center':
        profile.endowment_status == 'filled' ||
        profile.endowment_status == 'draft' ||
        !profile.is_wallet_created,
    }"
  >
    <div
      class="d-flex justify-center"
      v-if="
        profile.endowment_status == 'filled' ||
        profile.endowment_status == 'draft' ||
        !profile.is_wallet_created
      "
    >
      <v-card
        disabled
        flat
        width="454"
        height="400"
        class="me-6 rounded-xl endowment-card"
      >
        <img height="80" src="@/assets/icon/wallet-big.svg" alt="wallet" />
        <div class="arabic-black f22 gray--text mt-3 mb-16">
          {{ $t("home.endowmentPortfolio") }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          disabled
          width="144"
          height="47"
          depressed
          class="primary radius10"
        >
          <span class="text-body-1 font-weight-bold">{{
            $t("btn.create")
          }}</span>
        </v-btn>
      </v-card>
      <div class="text-center ms-7">
        <v-card
          flat
          width="454"
          height="400"
          class="rounded-xl endowment-card"
          to="endowment/waqf?step=1"
        >
          <img
            height="80"
            src="@/assets/icon/document-big.svg"
            alt="document"
          />
          <div class="arabic-black f22 black--text my-3">
            {{ $t("home.endowmentDocument") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            min-width="144"
            height="47"
            depressed
            class="primary radius10 px-6"
          >
            <span class="text-body-1 font-weight-bold">
              <div class="d-flex align-center">
                <img
                  class="me-2"
                  v-if="
                    profile.endowment_status == 'submitted' ||
                    profile.endowment_status == 'filled'
                  "
                  src="@/assets/icon/view.svg"
                  alt="view"
                />
                {{
                  profile.endowment_status == "submitted" ||
                  profile.endowment_status == "filled"
                    ? $t("btn.previewWaqf")
                    : $t("btn.create")
                }}
              </div>
            </span>
          </v-btn>
        </v-card>
        <v-btn
          v-if="profile.endowment_status == 'filled'"
          @click="submitEndowment"
          height="48"
          depressed
          class="primary radius10 px-3 mt-5"
        >
          <span class="text-body-1 font-weight-bold"
            >{{ $t("btn.verifyWaqf") }} (SAR
            {{ endowment.verification_price / 100 || 0 }})</span
          >
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch("getEndowmentV2");
  },
  methods: {
    async submitEndowment() {
      this.error = [];
      await this.$store
        .dispatch("actionEndowmentV2", { action: "submitted" })
        .then(() => {
          this.$store.dispatch("getProfile");
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    endowment() {
      return this.$store.getters.endowment;
    },
  },
  destroyed() {
    this.$store.dispatch("setEndowment");
  },
};
</script>

<style lang="scss">
.home {
  .endowment-card {
    padding: 107px 16px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
