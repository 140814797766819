var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",class:{
    'd-flex align-center justify-center':
      _vm.profile.endowment_status == 'filled' ||
      _vm.profile.endowment_status == 'draft' ||
      !_vm.profile.is_wallet_created,
  }},[(
      _vm.profile.endowment_status == 'filled' ||
      _vm.profile.endowment_status == 'draft' ||
      !_vm.profile.is_wallet_created
    )?_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"me-6 rounded-xl endowment-card",attrs:{"disabled":"","flat":"","width":"454","height":"400"}},[_c('img',{attrs:{"height":"80","src":require("@/assets/icon/wallet-big.svg"),"alt":"wallet"}}),_c('div',{staticClass:"arabic-black f22 gray--text mt-3 mb-16"},[_vm._v(" "+_vm._s(_vm.$t("home.endowmentPortfolio"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary radius10",attrs:{"disabled":"","width":"144","height":"47","depressed":""}},[_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("btn.create")))])])],1),_c('div',{staticClass:"text-center ms-7"},[_c('v-card',{staticClass:"rounded-xl endowment-card",attrs:{"flat":"","width":"454","height":"400","to":"endowment/waqf?step=1"}},[_c('img',{attrs:{"height":"80","src":require("@/assets/icon/document-big.svg"),"alt":"document"}}),_c('div',{staticClass:"arabic-black f22 black--text my-3"},[_vm._v(" "+_vm._s(_vm.$t("home.endowmentDocument"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary radius10 px-6",attrs:{"min-width":"144","height":"47","depressed":""}},[_c('span',{staticClass:"text-body-1 font-weight-bold"},[_c('div',{staticClass:"d-flex align-center"},[(
                  _vm.profile.endowment_status == 'submitted' ||
                  _vm.profile.endowment_status == 'filled'
                )?_c('img',{staticClass:"me-2",attrs:{"src":require("@/assets/icon/view.svg"),"alt":"view"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.profile.endowment_status == "submitted" || _vm.profile.endowment_status == "filled" ? _vm.$t("btn.previewWaqf") : _vm.$t("btn.create"))+" ")])])])],1),(_vm.profile.endowment_status == 'filled')?_c('v-btn',{staticClass:"primary radius10 px-3 mt-5",attrs:{"height":"48","depressed":""},on:{"click":_vm.submitEndowment}},[_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("btn.verifyWaqf"))+" (SAR "+_vm._s(_vm.endowment.verification_price / 100 || 0)+")")])]):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }